import { combineReducers } from 'redux';
import projectRequests from './reducer/projectReducer';
import auth from './reducer/authReducer';
import clientRequest from './reducer/clientReducer';
import sow from './sowDetails/reducer';
import station from './stationDetails/reducer';
import testReducer from './testDetails/reducer';
import log from './logDetails/reducer';
import equip from './pihEquipmentDetails/reducer';
import maintenanceReducer from './maintenance/reducer';
import materialReducer from './materialDescription/reducer'
import maintenanceAdminReducer from './admin/maintenance/reducer';
import checkIntervalReducer from './admin/intervals/reducer';
import productHierarchyReducer from './admin/productHierarchy/reducer';
import adminEquipTypeReducer from './admin/equipmentTypes/reducer';
import rentalGroupReducer from './admin/rentalGroups/reducer';
import adminEquipSnoReducer from './admin/equipmentSno/reducer';
import adminEquipChecksReducer from './admin/checks/reducer';
import historyReducer from './admin/checksHistory/reducer';
import externalMaterialGroupReducer from './admin/externalMaterialGroup/reducer';
import assetCompanyReducer from './admin/assetCompany/reducer';
import plantsReducer from './admin/plants/reducer';
import uploadReducer from './admin/upload/reducer';
import rolesReduceer from './roles/reducer';
import pihEquipmentCategoryReducer from './admin/category/reducer';
import materialConsumptionReducer from './materialConsumption/reducer';
import consumableReducer from './consumable/reducer';
import dsrReducer from './dsr/reducer'
import equipmentUsageReducer from './equipmentUsage/reducer'
import technicainLogReducer from './technicianLog/reducer'
import productionJointsReducer from './productionJoints/reducer'
import downTimeReducer from './downTime/reducer'
import ehsIssuesReducer from './ehsIssues/reducer';
import toolBoxReducer from './toolbox/reducer';
import toolBoxAdminReducer from './toolboxAdmin/reducer';
import CrceTechnicalReducer from './CRCETechnical/reducer';
import spareAdminReducer from './admin/sparesAdmin/reducer'
import spareDashBoardReducer from './sparesDashboard/reducer';

import reportEmailReducer from './reportEmail/reducer';
import billingDashBoardReducer from './billing/reducer';
import DCALReducer from './DCAL/reducer';
import heatProfileReducer from './heatProfile/reducer';
import employeesReducer from './admin/employees/reducer'

import { SIGNOUT } from './types';

const appReducer = combineReducers({
  projectRequests,
  auth,
  clientRequest,
  sow,
  station,
  testReducer,
  log,
  equip,
  maintenanceReducer,
  materialReducer,
  maintenanceAdminReducer,
  checkIntervalReducer,
  productHierarchyReducer,
  adminEquipTypeReducer,
  rentalGroupReducer,
  adminEquipSnoReducer,
  adminEquipChecksReducer,
  historyReducer,
  externalMaterialGroupReducer,
  assetCompanyReducer,
  plantsReducer,
  uploadReducer,
  rolesReduceer,
  pihEquipmentCategoryReducer,
  materialConsumptionReducer,
  consumableReducer,
  dsrReducer,
  equipmentUsageReducer,
  technicainLogReducer,
  productionJointsReducer,
  downTimeReducer,
  ehsIssuesReducer,
  toolBoxReducer,
  toolBoxAdminReducer,
  CrceTechnicalReducer,
  spareAdminReducer,
  spareDashBoardReducer,
  reportEmailReducer,
  billingDashBoardReducer,
  DCALReducer,
  heatProfileReducer,
  employeesReducer
});

const rootReducer = (state, action) => {
  const newState = action.type === SIGNOUT ? undefined : state;
  return appReducer(newState, action);
};

export default rootReducer;

import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
    uploadEquipmentfileSuccess,
    uploadEquipmentfileFailure,
    copyChecksSuccess,
    copyChecksFailure
} from './action';
import {
    UPLOAD_EQUIPMENT_FILE, COPY_CHECKS_DETAILS
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';
import axios from 'axios';


/** Get all listed equipments */
function* uploadEquipmentfile(payload) {
    try {
        const res = yield adminAxiosConfig.put(`/upload/pih-equipment-type-check`,payload);
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            notification.success({ message: 'File Successfully uploaded.' });
            yield put(uploadEquipmentfileSuccess({ response }));
        } else {
            yield put(uploadEquipmentfileFailure());
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield put(uploadEquipmentfileFailure());
        notification.destroy();
        notification.error({
            // message: 'A problem occured while fetching the pre signed Url Group.',
            description: `${error.error}`,
        });
    }
}

//function to copy checks from one equipment to another
function* copyChecks({
    payload
  }) {
    console.log("payload", payload)
    try {
      const res = yield axiosConfig.put('/copy-sow-tests', payload);
      const response = get(res, ['data', 'data'], {});
      notification.destroy();
      if (res.status === 200) {
        yield put(copyChecksSuccess({ response }));
        notification.success({
          message: `Test copied successfully.`,
        });
      } else {
        notification.warn({
          message: `Unable to copy. Please try again later `,
        });
      }
    } catch (error) {
      yield put(copyChecksFailure());
      notification.destroy();
      if (error.error === "Validation error") {
        notification.warn({
          message: 'Unable to copy. Please try again later !'
        });
      } else {
        notification.error({
          message: 'A problem occured while adding the Station. Please try again.',
          description: `${error.error}`,
        });
      }
    }
  }


function* uploadWatcher() {
    yield takeLatest(UPLOAD_EQUIPMENT_FILE, uploadEquipmentfile);
    yield takeLatest(COPY_CHECKS_DETAILS, copyChecks);
}

function* uploadSagas() {
    yield all([
        call(uploadWatcher),
    ]);
}

export default uploadSagas;
import get from 'lodash/get';
import {
    UPLOAD_EQUIPMENT_FILE,
    UPLOAD_EQUIPMENT_FILE_FAILURE,
    UPLOAD_EQUIPMENT_FILE_SUCCESS,
    COPY_CHECKS_DETAILS,
    COPY_CHECKS_DETAILS_SUCCESS,
    COPY_CHECKS_DETAILS_FAILURE
} from './types';

const initialState = {
    isUploadLoading: false,
    isChecksCopying: false,
};

const uploadReducer = (state = initialState, action) => {
    switch (action.type) {
        case UPLOAD_EQUIPMENT_FILE: {
            return {
                ...state,
                isUploadLoading: true
            }
        }

        case UPLOAD_EQUIPMENT_FILE_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                // rentalGroupList: resData.rows,
                uploadResponse: '',
                isUploadLoading: false
            }
        }

        case UPLOAD_EQUIPMENT_FILE_FAILURE: {
            return {
                ...state,
                isUploadLoading: false
            }
        }

        case COPY_CHECKS_DETAILS: {
            return {
                ...state,
                isChecksCopying: true
            }
        }
        case COPY_CHECKS_DETAILS_FAILURE: {
            return {
                ...state,
                isChecksCopying: false
            }
        }

        case COPY_CHECKS_DETAILS_SUCCESS: {
            return {
                ...state,
                isChecksCopying: false
            }
        }

        default: {
            return state;
        }
    }
}

export default uploadReducer;
/* eslint-disable linebreak-style */
import {
  UPLOAD_EQUIPMENT_FILE, UPLOAD_EQUIPMENT_FILE_FAILURE, UPLOAD_EQUIPMENT_FILE_SUCCESS, COPY_CHECKS_DETAILS, COPY_CHECKS_DETAILS_SUCCESS, COPY_CHECKS_DETAILS_FAILURE
} from './types';

const uploadEquipmentfile = (payload) => ({
  type: UPLOAD_EQUIPMENT_FILE, ...payload
});

const uploadEquipmentfileSuccess = (payload) => ({
  type: UPLOAD_EQUIPMENT_FILE_SUCCESS, ...payload,
});

const uploadEquipmentfileFailure = () => ({
  type: UPLOAD_EQUIPMENT_FILE_FAILURE,
});

//copy checks
const copyChecks = (payload) => ({
  type: COPY_CHECKS_DETAILS,
  payload
});

const copyChecksSuccess = (payload) => ({
  type: COPY_CHECKS_DETAILS_SUCCESS,
  ...payload
});

const copyChecksFailure = () => ({
  type: COPY_CHECKS_DETAILS_FAILURE
});


export {
  uploadEquipmentfile,
  uploadEquipmentfileSuccess,
  uploadEquipmentfileFailure,
  copyChecks,
  copyChecksSuccess,
  copyChecksFailure
}